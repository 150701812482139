import React from "react"
import { Helmet } from "react-helmet"
import { Nav, Navbar, Row, Col, Image } from "react-bootstrap";
// import { Nav, Navbar, Button, Form, FormControl, Col } from "react-bootstrap";
import "../styles/style.css";

//const ListLink = props => (
//    <li style={{ display: `inline-block`, marginRight: `1rem` }}>
//        <Link to={props.to}>{props.children}</Link>
//    </li>
//)

// function onSearchButtonClicked() {
//     // clear the text field
//     // var el = document.getElementById('searchString');
//     // el.value = el.defaultValue;
//     // console.log(el);

//     // give the answer to life the universe and everything
//     alert(42);
// }

export default function Layout({ children }) {
    return (
        <div>
            <div className="application">
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>TheWebPleb</title>
                    <link rel="canonical" href="https://thewebpleb.com" />
                </Helmet>
            </div>
            <div className='main-container'>
                <Navbar bg="light" variant="light" expand="lg" style={{ backgroundColor: `#FFFFFF` }}>
                    <Navbar.Brand href="/">TheWebPleb</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto nav-black-text">
                            <Nav.Link className="" href="#">Blog</Nav.Link>
                            <Nav.Link className="" href="#">Store</Nav.Link>
                            <Nav.Link className="" href="/about">About</Nav.Link>
                            <Nav.Link className="" href="/contact">Contact</Nav.Link>
                            {/* <Nav.Link className="" href="/pricing">Pricing</Nav.Link>
                            <Nav.Link className="" href="/showcase">Showcase</Nav.Link>
                            <Nav.Link className="" href="/about">About</Nav.Link>
                            <Nav.Link className="" href="/contact">Contact</Nav.Link> */}
                        </Nav>
                        {/* <Col style={{ display: `flex`, justifyContent: `flex-end`, alignItems: `center`}}>
                            <Form inline className="search-form">
                                <FormControl id="searchString" type="text" placeholder="meaning of life" className="mr-sm-2" />
                                <Button variant="outline-success" className="btn-search" onClick={onSearchButtonClicked}>Search</Button>
                            </Form>
                        </Col> */}
                    </Navbar.Collapse>
                </Navbar>

                { children }

                <div className="my-footer">
                    {/* <Row className="mt-5">
                        <Col sm={10} lg={6} className="align-items-center justify-content-end">
                            <Image style={{height: `80px`}}src="ia.png"/>
                        </Col>
                        <Col sm={10} lg={6} className="align-items-center">
                            <Image src="ignition.png"/>
                        </Col>
                    </Row> */}
                    <Row className="justify-content-center">
                        <Nav className="text-center keep-footer-text-down footer-links" activeKey="/home">
                            <Nav.Item className="footer-links" style={{ textDecoration: `none` }}>
                                <Nav.Link className="footer-links" href="/" style={{ textDecoration: `none` }}>Home</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link className="footer-links" href="#">Blog</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link className="footer-links" href="#">Store</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link className="footer-links" href="/about">About</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link className="footer-links" href="/contact">Contact</Nav.Link>
                            </Nav.Item>
                            {/* <Nav.Item>
                                <Nav.Link className="footer-links" href="/pricing">Pricing</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link className="footer-links" href="/showcase">Showcase</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link className="footer-links" href="/about">About</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link className="footer-links" href="/contact">Contact</Nav.Link>
                            </Nav.Item> */}
                        </Nav>
                    </Row>
                    <Row className="justify-content-center">
                        <p className="text-center footer-tagline footer-tagline">Copyright © 2021 TheWebPleb </p> 
                    </Row>
                </div>
            </div>
        </div>
    )
}